// @flow
import React, { Component, type Node } from 'react';
import styled from 'styled-components';

import { LoadingSpinner } from 'components/global/icons';

export const StyledButton = styled.button`
  appearance: none;
  background: ${props => props.theme.colors.hiCta};
  border: 2px solid ${props => props.theme.colors.hiCta};
  border-radius: 4px;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: ${props => props.theme.typography.fontStack};
  font-size: 18px;
  line-height: 23px;
  margin: ${({ margin }) => margin};
  margin-bottom: ${props => (props.removeMargins ? '0' : '20px')};
  max-width: ${props => (props.fullWidth ? 'auto' : `240px`)};
  padding: ${props => (props.loading ? `7px` : `15px`)};
  text-align: center;
  text-decoration: none;
  transition: opacity 0.2s ease-in;
  width: ${({ width }) => width || '100%'};

  @media (min-width: 768px) {
    margin-bottom: 0;
  }

  &:hover {
    background: ${props => props.theme.colors.hiCtaHover};
    border-color: ${props => props.theme.colors.hiCtaHover};
  }

  &:active {
    box-shadow: inset 0 3px 3px 0
      ${props => props.theme.colors.hiAccentBlueHover};
    outline: none;
  }

  &.alt {
    background: white;
    color: ${props => props.theme.colors.hiAccentBlue};

    &:hover {
      background: ${props => props.theme.colors.lightestBlue};
    }
  }

  &.altGreen {
    background: ${props => props.theme.colors.statusGreen};
    border-color: ${props => props.theme.colors.statusGreen};

    &:hover {
      background: ${props => props.theme.colors.lightGreenHover};
      border-color: ${props => props.theme.colors.lightGreenHover};
    }
  }

  &.altRed {
    background: white;
    color: ${props => props.theme.colors.red};
    border-color: ${props => props.theme.colors.red};

    &:hover {
      background: ${props => props.theme.colors.lightestRed};
    }
  }

  &.altWhite {
    background: transparent;
    color: #ffffff;
    border-color: #ffffff;
    max-width: 100%;

    &:hover {
      background: ${props => props.theme.colors.hiAccentBlue};
    }
  }

  &.danger {
    background: white;
    border-color: ${props => props.theme.colors.warningRed};
    box-shadow: 0 1px 6px 0 ${props => props.theme.colors.shadowRed};
    color: ${props => props.theme.colors.warningRed};

    &:hover {
      background: ${props => props.theme.colors.lightestRed};
    }

    &:active {
      box-shadow: inset 0 3px 3px 0 ${props => props.theme.colors.shadowRed};
    }
  }

  &[disabled] {
    background: white;
    border-color: ${props => props.theme.colors.hiLightBlue};
    box-shadow: none;
    color: ${props => props.theme.colors.hiLightBlue};
    cursor: default;

    &:hover {
      background: white !important;
      border-color: ${props => props.theme.colors.uiGrey};
    }
  }
`;

type Props = {
  id?: string,
  children?: Node,
  className: string,
  onClick: any => void | Object,
  loading: boolean,
  disabled?: boolean | (() => void),
  fullWidth: boolean,
  type: string,
  margin?: string,
  multiclick?: boolean,
  name: string,
  removeMargins: boolean,
  style: Object,
  width?: string,
};

class Button extends Component<Props> {
  static defaultProps = {
    children: null,
    className: '',
    onClick: () => {},
    status: '',
    disabled: false,
    loading: false,
    type: '',
    name: '',
    style: {},
    fullWidth: false,
    removeMargins: false,
  };

  render() {
    const {
      className,
      disabled,
      type,
      name,
      children,
      id,
      fullWidth,
      margin,
      removeMargins,
      loading,
      width,
    } = this.props;

    return (
      <StyledButton
        id={id}
        style={this.props.style}
        className={`${className}`}
        onClick={this.props.onClick}
        disabled={disabled}
        type={type}
        margin={margin}
        name={name}
        fullWidth={fullWidth}
        removeMargins={removeMargins}
        loading={loading}
        width={width}
      >
        {loading ? <LoadingSpinner /> : children}
      </StyledButton>
    );
  }
}

export default Button;
