import React, { Component } from 'react';
import { Container, Col, Row } from 'components/grid';

import Module, { ModuleHeader } from 'components/global/layout/Module';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
    h3 {
        font-weight: bold;
        font-size: 20px;
        margin: 24px 0 10px;
    }
    h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 22px 0 10px;
    }
    h5 {
        font-weight: bold;
        margin: 20px 0 10px;
    }
    
    ul {
        list-style-type: disc;
        margin-bottom: 16px;
    }
    
    li {
        margin: 6px 0 6px 24px;
    }
    
    ul ul {
        list-style-type: circle;
    }
    
    li li {
        margin-left: 48px;
    }
    
    p {
        margin: 10px 0;
    }
`;

class PrivacyPolicy extends Component {

  render() {
    return (
      <section>
        <Module>
          <ModuleHeader>Our Privacy Policy</ModuleHeader>
          <StyledContainer>
            <Row>
              <Col>
                <p><i>Last Updated: May 2020</i></p>
                <p>
                  We are committed to protecting the privacy of our Users.
                </p>
                <p>
                  Please read this Privacy Policy carefully as it contains important information about our practices -
                  how we will collect, use and share your personal information, information that we collect from you,
                  and that we receive from third parties.
                </p>
                <p>
                  We are Home Instead SC Limited, a company registered in England and Wales with company registration
                  number 12580451, trading as SuperCarers (collectively "we", "us" or "our" in this privacy policy). Our
                  registered office address is Unit 2 Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, WA4
                  4PG, UK and our trading address is Floor 4, 15 Bonhill Street, London, EC2A 4DN, UK.
                </p>
                <p>
                  Contracts where Super Carers Ltd acted as the controller have been transferred to Home Instead SC
                  Limited. Please see our <a href='/terms-and-conditions'>Terms and Conditions</a> for further
                  information.
                </p>
                <p>
                  This Privacy Policy applies to both Care Seekers and Care Givers, and, with regard to transparency
                  provisions according to General Data Protection Regulation (GDPR), to other individuals whose data we
                  may process when visiting our Website (supercarers.com) or accessing our Platform
                  (liveincareapp.homeinstead.co.uk), except for our employees.
                </p>
                <p>
                  All your Personal Information shall be used in accordance with the General Data Protection Regulation
                  (GDPR). For the purposes of the GDPR, Home Instead SC Limited is the data controller, where you are
                  visiting or using our services through our Website (supercarers.com) or accessing our Platform
                  (liveincareapp.homeinstead.co.uk).
                </p>

                <h3>The basis for the processing of your data</h3>
                <p>
                  SuperCarers processes your data for the performance of the contract that you have entered into with
                  Home Instead SC Limited for the use of the SuperCarers service and that is governed by our Terms and
                  Conditions, and/or in order to take steps at your request to enter into this contract.
                </p>
                <p>
                  When it comes to special categories of data such as health data, we rely on Article 9(2)(h) GDPR
                  exception in connection with Schedule 1, Part 1 of the Data Protection Act 2018, pursuant to which we
                  can process the data for the provision of social care services.
                </p>
                <p>
                  In addition, some data is processed based on SuperCarers legitimate interest, notably safety and
                  security of Care Seekers and Care Givers, web and app analytics for the purposes of enhancing web and
                  app performance, website logs etc. to prevent fraudulent or other malicious activities, website
                  visitor logs for cybersecurity and/or error tracking purposes.
                </p>
                <p>
                  We may ask for your consent in other cases where we process your data. Such consent can be revoked at
                  any time by contacting us at <a
                  href='mailto:hello@liveincare.homeinstead.co.uk'>hello@liveincare.homeinstead.co.uk</a>
                </p>

                <h3>1. Policy Updates</h3>
                <p>
                  From time to time this Privacy Policy may be updated and a new version will be posted on our
                  Website/Platform. As such you should check this page to review the changes and ensure that you
                  continue to agree to the Policy. The date the Policy was last updated will be displayed at the top of
                  the page.
                </p>

                <h3>2. Information collected</h3>

                <h4>2.1 Personal information</h4>
                <p>
                  Personal Information is information that could potentially identify you individually. We may collect,
                  store and/or use the following types of personal information:
                </p>
                <ul>
                  <li>
                    Information that you give to us. This might be that which you provide via forms on; the Website,
                    the Platform, or provide by e-mail, over the phone or other such means.
                  </li>
                  <li>
                    This includes information provided for the purpose of registering for or using our service, in
                    searching our Website or Platform, in subscribing for our services (email notifications and/or
                    newsletters) or for any other reason. This includes but is not limited to;
                    <ul>
                      <li>
                        Contact information: your name, email address and phone number
                      </li>
                      <li>
                        Information about the Care needed: the type of Care, the address where the Care is required and
                        the name of the Care Recipient.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Information relating to any transactions made through the platform, including but not limited to
                    booking or providing the Care and corresponding financial and credit card and billing information.
                    <ul>
                      <li>
                        We do not store Credit or Debit card information submitted through the Platform to pay Care
                        Givers and our booking fee. This payment information is transmitted directly to a third-party
                        payment card processor, Stripe. Please find their Privacy Policy at <a
                        href='www.stripe.com/gb/privacy'>www.stripe.com/gb/privacy</a>.
                      </li>
                      <li>
                        Stripe notifies us whether your transaction Payment has been successful or not, and might
                        communicate some details on issues, for example if a payment fails due to insufficient funds or
                        other
                        reasons.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Information provided on health and/or medical conditions, experience requirements, and personal
                    preferences.
                    <ul>
                      <li>
                        In addition Care Givers may record information about Care provided, Care Seekers and Care
                        Recipients in the course of their service delivery, which is connected to your account and
                        other personal information provided. This information may be stored and made available to other
                        Care Givers booked for the purposes of best serving you.
                      </li>
                      <li>
                        Special categories of personal data like health data, which merit higher protection, will be
                        processed for health-related purposes only where necessary to achieve those purposes for the
                        benefit of care recipients, in particular in the context of the management of health pursuant to
                        contract with a health professional and subject to suitable safeguards. Care Givers health
                        information will be processed in terms of ensuring Care Seekers and Care Givers own security
                        when providing the services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Ratings and reviews provided – about services provided through the platform, including about the
                    Care Givers.
                  </li>
                  <li>
                    We may also receive and store information about you from third parties, involved in or connected to
                    the Care, such as Social Services.
                  </li>
                  <li>
                    We may receive and store information about you where you interact with us through social media. This
                    will include personal information that is available on your profile such as; name, location and work
                    details.
                  </li>
                </ul>
                <p>
                  If you call our phone lines, calls are recorded for training and monitoring purposes
                </p>

                <h4>2.2 Personal information relating to Care Recipients</h4>
                <p>
                  Before disclosing to us any personal information relating to another person you must obtain their
                  consent to the disclosure and processing of that information in accordance with the terms of this
                  Privacy Policy. We reserve the right to take reasonable security steps to ensure that the care
                  recipient is aware of the data processing, which may result in asking for further
                  information/documentation.
                </p>
                <p>
                  This might be where the Care Seeker and the person receiving the Care (the “Care Recipient”), is not
                  the same person, and as such you might disclose personal information about them.
                </p>

                <h4>2.3 Additional personal information collected for Care Givers</h4>
                <p>
                  If you are using the Website/Platform to apply or join as one of our registered Care Givers, we will
                  collect and store further personal information, including but not limited to:
                </p>
                <ul>
                  <li>
                    Information you supply to us during the application process and thereafter. Personal data such as:
                    <ul>
                      <li>
                        Qualifications, professional skills and other experiences (CV), personal preferences (activities
                        of daily living you enjoy or animal preferences), nationality, languages, results of background
                        checks (see below) and any other personal data which you disclose to use for use in your
                        profile.
                      </li>
                      <li>
                        A photograph of your face, which we may take for you and display on your profile to Care
                        Seekers. A photograph of your face is required in order that the Care Seeker can know who the
                        person they are booking is and identify them when letting them into their home. This is
                        important for the safety and security of Users.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Supplemental information about you from third parties, for example results of background checks
                    carried out by Trust ID and Security Watchdog including and not limited to; Enhanced DBS (criminal
                    records check including spent convictions), identity and right to work checks. The results of these
                    checks will be shared with us by the third party provider. Information obtained for the purposes of
                    verifying your work history and reference checks or that information obtained by way of face to face
                    interview.
                  </li>
                </ul>

                <p>
                  Information might be provided; directly from you; from a third party hiring platform or job board,
                  which you uploaded a CV or other personal information to; or, where you have been referred to us to
                  work through the platform; or engaged with us via social media channels. We will not use data from the
                  open internet in this regard; we will only gather information about you where you have previously
                  intended to let us know. Personal information collected from social media channels may include that
                  which is detailed on your profile such as; name, location, work details.
                </p>
                <p>
                  We may collect data and feedback from clients and reference it against your profile as this is
                  important for the safety of Users. This information will not be publicly available on your
                  profile.
                </p>

                <h4>
                  2.4 Other Information Collected -- technical and technology information as it relates to your
                  data:
                </h4>

                <ul>
                  <li>
                    We may collect and store certain information automatically through technology each time you visit
                    the Website or Platform. This is typically designed to help us learn and improve the way we serve
                    you. Examples of this information include the following:
                    <ul>
                      <li>
                        information about how you interact with the Website/Platform - the computer or device used for
                        access, the internet protocol (IP) address used to connect your computer or device to the
                        internet, other connection information such as: browser type, version, location and language
                        preferences, and your operating system
                      </li>
                      <li>
                        information about your visit including: referral source, page visits, duration and navigation
                        information, searches made, the full Uniform Resource Locators (URL)
                      </li>
                      <li>
                        We also may receive information about you from other third parties we work with - such as any
                        websites or social media platforms (for example, Facebook), other advertising and analytics
                        networks, and other channels who link Your Personal Data to our Website/Platform.
                      </li>
                    </ul>
                  </li>
                </ul>

                <h3>3. Using your Personal Information:</h3>
                <h4>3.1 For All Users:</h4>
                <p>
                  We and our representatives may use your personal information for the following purposes:
                </p>
                <ul>
                  <li>
                    carrying out our obligations under Our Terms & Conditions;
                  </li>
                  <li>
                    authorising payments and/or a bill or receipt to or from you;
                  </li>
                  <li>
                    administering and maintaining the account you hold with us;
                  </li>
                  <li>
                    ensuring that Our Service is presented in the most effective manner for you and your computer or
                    mobile device;
                  </li>
                  <li>
                    providing you with information, products and/or services that you request from us, and other
                    commercial communications;
                  </li>
                  <li>
                    where you have consented and opted in to specific communications such as those for direct marketing
                    purposes, information about other services or products which we feel may interest you;
                  </li>
                  <li>
                    notifying you about a change to Our Service;
                  </li>
                  <li>
                    record-keeping and tracking your use of Our Service including through the use of cookies;
                  </li>
                  <li>
                    requesting feedback on Services and to address and investigate service complaints that might arise
                    or violation of any of our Terms, Policies or Standards; and
                  </li>
                  <li>
                    to conduct troubleshooting, data analysis and internal reporting
                  </li>
                </ul>

                <p>
                  We will not provide your information to any third parties for direct marketing purposes without your
                  express consent.
                </p>

                <h4>3.2 For Care Seekers and Care Recipients - Additional Information:</h4>
                <p>
                  We and our representatives may use your personal information for the following purposes:
                </p>

                <ul>
                  <li>
                    ensuring Care Givers can fulfil a booking;
                  </li>
                  <li>
                    providing Care Givers with relevant details of the Care previously booked and the records of other
                    Care Givers providing Care to the Care Recipient
                  </li>
                  <li>
                    providing Care Givers with relevant details of the Care previously booked and the records of other
                    Care Givers providing Care to the Care Recipient
                  </li>
                  <li>
                    providing Care Givers with information stored on your account about: health and medical needs,
                    experience requirements and other personal preferences including activities of daily living
                  </li>
                  <li>
                    Once you have completed your care request, we will share the following information with the suitable
                    carers in order to find the best match for you: first name, first three digits of the postcode where
                    care is required and the description of needs.
                  </li>
                  <li>
                    Once you have completed your care request, we will share the following information with the suitable
                    carers in order to find the best match for you: first name, first three digits of the postcode where
                    care is required and the description of needs.
                  </li>
                  <li>
                    Where you are referred to us by an insurance company or business partner, we may exchange with them
                    basic information to identify you, such as your insurance policy number. We may further share with
                    the
                    insurance company aggregate basic information regarding the care type that has been requested
                  </li>
                </ul>

                <h5>Care seekers matching with third party care services</h5>
                <ul>
                  <li>
                    Where you agree for us to refer you to a third party Care services provider, such as a Care or
                    nursing agency, we will share pseudo-anonymised information for the purpose of meeting your
                    requirements as a Care Seeker. The agencies who can meet their need will be presented to you and you
                    can then choose whether SuperCarers shares their full information with a chosen care provider for them
                    to contact you directly, or whether they would prefer to receive the contact information of the
                    relevant agency so they can contact you directly. If you do not agree for us to share your information
                    we will share the agencies information directly with you.
                  </li>
                </ul>

                <h5>Care Seekers accessing SuperCarers Care Expert services</h5>
                <p>
                  SuperCarers offers certain care seekers access to a ‘Care Expert’ to discuss their care needs and
                  receive high-level advice and support on their care options. As part of this call SuperCarers will
                  record the personal information provided in order to gain insights on the care needs of these Users.
                  'Care Expert' calls are booked through a third party booking system called 'Calendly' and information
                  is provided via a form powered by third party 'Typeform'.
                </p>
                <p>
                  Some users accessing this service may also choose to make their care enquiry online where they submit
                  this information directly to SuperCarers. Certain users who are employees or service users of business
                  partners of SuperCarers may be offered upfront the choice of an independent carer or a managed care
                  service via a third party agency. Where the user opts for an independent carer their information will
                  be treated as a care seeker as outlined above. Where a User opts for a Care agency they will be
                  treated as Care Seekers matching with third party care services as above.
                </p>

                <h4>3.3 For Care Givers - Additional Information:</h4>
                <p>
                  Where you have applied to join or have been accepted as a registered Care Giver on the
                  Website/Platform, you submit personal information to us and for publication on our Website/Platform
                  and as such we may use your information in accordance with our Terms & Conditions.
                </p>
                <p>
                  We may use this personal information in the following ways:
                </p>

                <ul>
                  <li>
                    inviting you to apply or to complete your application;
                  </li>
                  <li>
                    to contact you in respect of an application to join the Platform -- such as arranging an interview,
                    induction or any training;
                  </li>
                  <li>
                    to assess your CV, qualifications and any other information provided to ascertain your suitability
                    to join the platform;
                  </li>
                  <li>
                    to complete the registration process, including to contact third parties to obtain references on
                    you;
                  </li>
                  <li>
                    to provide you with information regarding job opportunities with Care Seekers that may be of
                    interest to you based on the information we have collected;
                  </li>
                  <li>
                    to monitor your ongoing compliance and administer your account including: criminal records
                    information shared with us by our third party DBS provider, RTW documentation, updates to training
                    and other compliance information;
                  </li>
                  <li>
                    to check the status of your DBS certificate on a weekly basis if it is registered online with the
                    DBS Update Service, using their Multiple Status Check Facility;
                  </li>
                  <li>
                    to provide your profile to Care Seekers to facilitate a booking, which is available on the internet.
                  </li>
                  <li>
                    to provide your details to care organisations in the Home Instead franchise network for other job
                    opportunities that may be of interest to you.
                  </li>
                </ul>

                <p>
                  Customers can search for Carer Givers in their local area on our website, using a postcode search, and
                  can view limited profiles of those in their area. The limited profile includes: picture, first name,
                  surname (initial only), years' experience in care, driving status, pet preference and languages.
                  Customers will not receive any further details or contract details until you propose yourself to a
                  job.
                </p>
                <p>
                  When you propose yourself for a job, your personal profile is made available to the family, which
                  includes: full first name, surname (initial only), picture, summary key info and background checks
                  info (DBS checked, driving or car status, languages, pet preference, public liability status, smoking
                  or not), condition experience, services provided, qualifications and a map with a broad indication of
                  where you live. Once the family receives the "shortlist of carers", they may want to interview you; in
                  that case, SuperCarers will share your contact details: phone number and email.
                </p>

                <h3>4. Retention of personal data</h3>
                <p>
                  The Platform allows Users who are Care Seekers to connect with Users who are Care Givers and provides
                  tools to help facilitate this process. As such it is vital, we retain personal data on Users and from
                  closed accounts for as long as necessary to fulfil the purposes the data was collected for, including
                  for the purposes of satisfying any legal, accounting, or reporting requirements and to comply with
                  other obligations such as safeguarding Users, to the extent that it is permitted by Law.
                </p>
                <p>
                  We may delete accounts or specific information before account deactivation where we believe it may no
                  longer be relevant or where an account has been inactive for more than a year.
                </p>
                <p>
                  If you have sent us an email or other correspondence, we will retain your information until we are
                  satisfied it is outdated or no longer relevant, subject to your exercise of the right to
                  erasure.
                </p>

                <h3>5. Disclosure of your information:</h3>
                <p>
                Subject to the need to perform the contract with you based on our Terms and Conditions and our
                legitimate interests, we may share your personal information in the ways described below:
                </p>
                <p>
                We do not allow our third-parties to use your personal data for their own purposes (such as marketing
                or reselling) and we only permit them to process your personal data for specified purposes and in
                accordance with our instructions. Recipient category and explanation as follows.
                </p>

                <h5>Prospective seller or buyer of our business or assets</h5>
                <p>
                Just in the event that we sell or buy any business or assets. If a change happens to our business,
                then the new owners may use your personal data in the same way as set out in this Privacy
                Policy.
                </p>

                <h5>Other companies and organisations</h5>
                <p>
                  Where we are under a duty to disclose or share your personal information in order to comply with any
                  legal obligation, or in order to enforce or apply such other terms as apply to our relationship, or to
                  protect the rights, property, or safety of our customers, ourselves or others. This includes
                  exchanging information with other companies and organisations for the purposes of fraud protection,
                  credit risk reduction and relating to safeguarding issues.
                </p>

                <h5>
                  Social Services, Adult Safeguarding Board of the relevant Local Authority, General
                  Practitioner (GP), NHS clinicians or nurses, private consultants, specialists or other persons
                  involved in your ongoing Care
                </h5>
                <p>
                  If we determine that such disclosure is necessary in connection with any investigation or complaint
                  regarding your use of the Platform or if we are under a legal obligation.
                </p>

                <h5>
                  Third-party tools, software and service providers: hosting, analytics, CRM, emailing, payment,
                  scheduling, administration and support, app integration, communication, identity verification,
                  system administrators
                </h5>
                <p>
                  We may share your personal data with third-parties who perform functions on our behalf in connection
                  with the operation of our business and assist us in the communication with you, the performance of the
                  contract you have entered into with us and the improvement and optimisation of our website.
                </p>

                <h5>
                  Other companies within the Home Instead Senior Care® Franchise network. For further
                  information about the Franchise, please visit this <a href='https://www.homeinstead.co.uk/'>page</a>
                </h5>
                <p>
                  We may share your personal data with Home Instead Senior Care UK Limited and Home Instead Inc. for
                  internal administrative purposes.
                </p>

                <h5>Any other third parties</h5>
                <p>
                  Solely with your consent.
                </p>

                <h3>6. Cookies:</h3>
                <p>
                  Certain parts of Our Website/Platform use “cookies” to keep track of your visit and to help you
                  navigate between sections. A cookie is a small data file that certain websites store on your
                  computer’s hard-drive when you visit such websites. Cookies can contain information such as your user
                  ID and the pages you have visited.
                </p>
                <p>
                  We use cookies on Our Website/Platform to enable us to deliver content that is specific to your
                  interests and gives us an idea of which parts of Our Service you are visiting and to recognise you
                  when you return to Our Service. Our cookies do not read data from your computer’s hard-drive or read
                  cookies created by other websites that you have visited.
                </p>
                <p>
                  You may refuse to accept cookies by activating the setting on your browser which allows you to refuse
                  the setting of cookies. Unless you have adjusted your browser settings so that it will refuse cookies,
                  our system will issue cookies when you access Our Service.<br />
                  Please note Third Party, providers of content and services may also use cookies over which we have no
                  control.
                </p>
                <p>
                  Types of cookies used on SuperCarers:
                </p>
                <p>
                  SuperCarers uses different types of cookies. Permanent or persistent cookies are those that are not
                  removed from your browser or computer after a session ends and you close your browser. These are used
                  to recognise you as a User and for example remember the details about care requests made by a User to
                  enable sign up functionality, should the User leave and return to the site. Session cookies enable us
                  to track you while using our Website and for example make care bookings.
                </p>
                <p>
                  SuperCarers uses a number of third party services to embed content such as reviews and videos, and to
                  help analyse site features and are primarily for own internal purposes. An example of this would be
                  Google Analytics, which provides information, insights and reporting on the use of the Website.
                </p>

                <h3>7. Security:</h3>
                <p>
                  Our Service has numerous security measures in place to protect the loss, misuse and alteration of
                  information under our control, such as passwords and firewalls. We do take data security seriously and
                  will use our reasonable endeavours to protect the integrity of your personal information.
                </p>
                <ul>
                  <li>
                    If you register to use Our Service, in particular if you have an account with us, you will be
                    asked to create a password. You must keep this password confidential and must not disclose it or
                    share it with anyone. You will be responsible for all activities that occur under your password.
                    If you know or suspect that someone else knows your password you should notify us immediately at
                    the address provided at the end of this Policy. If we have reason to believe that there is likely
                    to be a breach of security or misuse of Our Service, we may require you to change your password or
                    we may suspend your account until your identity has been verified and the account has been
                    satisfactorily secured.
                  </li>
                  <li>
                    Our Service may, from time to time, contain links to and from other websites. If you follow a link
                    to any of these websites, please note that these websites have their own privacy policies and that
                    we do not accept any responsibility or liability for these policies. Please check these policies
                    before you submit any personal data to these websites.
                  </li>
                </ul>
                <p>
                  Additionally, personal information and content requested may be transmitted electronically to
                  addresses provided by you (email for example) and this is done so at your own risk and you understand
                  these risks.
                </p>

                <h3>8. Storing Your Personal Data - where do we store it?</h3>
                <p>
                  Your personal data may be collected, processed and/or otherwise transferred to a destination outside
                  the European Economic Area (“EEA”) and in particular, the United States.
                </p>
                <p>
                  Data we collect from you is primarily stored on servers located in the EU/EEA, however in particular
                  third party technologies and services we use may store data in the United States, which results in
                  this transfer of information internationally outside of the EEA. Please see section 9 for further
                  information.
                </p>
                <p>
                  We will take all reasonable steps necessary to ensure that your data is treated securely and
                  appropriately in accordance with this policy.
                </p>
                <p>
                  We have put the necessary steps in place to ensure all company laptops are encrypted and have
                  appropriate protection against viruses, malware, ransom-ware and malicious code and are regularly
                  updated.
                </p>

                <h3>9. International Data Transfers</h3>
                <p>
                  The data that we collect from you may be transferred to, and stored at, a destination outside the
                  European Economic Area (“EEA”), where the laws on processing personal data may be less stringent than
                  in your country. It may also be processed by staff operating outside the EEA who work for us or for
                  one of our suppliers. This includes staff engaged in, among other things, the fulfilment of your
                  order, the processing of your payment details and the provision of support services.
                </p>
                <p>
                  We will take all steps reasonably necessary to ensure that your data is treated securely and in
                  accordance with this Privacy Policy, by ensuring at least one of the following safeguards is
                  implemented:
                </p>
                <ul>
                  <li>
                    transferring your personal data to countries that have been deemed to provide an adequate level of
                    protection for personal data by the European Commission;
                  </li>
                  <li>
                    entering into specific contractual terms which have been approved by the European Commission and
                    which give personal data the same protection as within the EEA;
                  </li>
                  <li>
                    where your data will be transferred to the US, ensuring that the third party to which we are
                    transferring your data is part of the Privacy Shield, which requires them to provide similar
                    protection to personal data shared between the Europe and the US. This is the case of personal
                    data transfers to Home Instead Inc. Home Instead Inc. participates in the EU-US and Swiss-US
                    Privacy Shield Framework. They have certified with the Department of Commerce that they adhere to
                    the Privacy Shield Principles. For further information, you can visit their <a
                    href='https://www.privacyshield.gov/participant?id=a2zt0000000PKEOAA4&status=Active'>Privacy
                    Shield page</a>.
                  </li>
                </ul>
                <p>
                For further information on the safeguards used, please contact us at <a
                href='mailto:hello@liveincare.homeinstead.co.uk'>hello@liveincare.homeinstead.co.uk</a>
                </p>

                <h3>10. Your rights:</h3>
                <ul>
                  <li>
                    Applicable Laws - We are committed to protecting your privacy, and will only use your personal
                    information in accordance with the General Data Protection Regulation, the Data Protection Act
                    2018, and the Privacy and Electronic Communications (EC Directive) Regulations 2003 together with
                    any other laws applicable to the protection of personal data in force from time to time in England
                    and Wales and any related regulations and guidance (as from time to time amended, extended,
                    re-enacted or consolidated) and all subordinate legislation, regulations and guidance made
                    pursuant to any of them;
                  </li>
                </ul>
                <p>
                  You have the right to ask us not to process your personal data for marketing purposes. We will inform
                  you if we intend to use your data for such purposes or if we intend to disclose your information to
                  any third party for such purposes.
                </p>
                <p>
                  Access - You are entitled by law to ask for a copy of your personal Information at any time free of
                  charge.
                </p>
                <p>
                  You have the right to obtain without undue delay the rectification of inaccurate personal data
                  concerning you, including the right to have incomplete personal data completed e.g. by means of
                  providing a supplementary statement.
                </p>
                <p>
                  You have the right to erasure of your data, which you can exercise by means of the deactivation of
                  your account. We are not required to delete the information important for the establishment, exercise
                  or defence of legal claims, or that we need to retain in order to comply with the law.
                </p>
                <p>
                  You have the right of restriction of processing, where the data is wrongfully processed but should not
                  be erased for a reason listed in Article 18 (1) of the GDPR.<br />
                  Until a download option has been developed based on universally recognised standards, you may exercise
                  your right of data portability by obtaining your data by sending us an email request.
                </p>
                <p>
                  You can exercise your rights at any time by writing to us at our trading address:
                </p>
                <p>
                  Home Instead SC Limited, Floor 4, 15 Bonhill Street, London, EC2A 4DN, UK.
                </p>
                <p>
                  Alternatively, you can write to us at our registered address:
                </p>
                <p>
                  Home Instead SC Limited, Unit 2 Walnut Tree Farm, Northwich Road, Lower Stretton, Warrington, WA4 4PG,
                  UK.
                </p>
                <p>
                  Alternatively email us on <a
                  href='mailto:hello@liveincare.homeinstead.co.uk'>hello@liveincare.homeinstead.co.uk</a>
                </p>

                <h3>11. Third party websites</h3>
                <p>
                  Our Website/Platform contains links to other Websites and Services. These Websites and Services will
                  have their own Privacy Policy and we accept no liability or responsibility for these Policies or how
                  these might collect, store and use Your Personal Information.
                </p>

                <h3>12. Our Contact information</h3>
                <p>
                  If you have any questions about Our Privacy Policy or how we treat your personal information please do
                  not hesitate to contact us by email at: <a
                  href='mailto:hello@liveincare.homeinstead.co.uk'>hello@liveincare.homeinstead.co.uk</a>.
                </p>

                <h4>Data Protection Officer contact details:</h4>
                <p>
                  Eagle House, 163 City Road, London,EC1V 1NR
                </p>
                <p>
                  Contact email: <a href='mailto:dpo@aphaia.co.uk'>dpo@aphaia.co.uk</a>
                </p>
                <p>
                  Contact telephone: (\+44) (0)20 3917 4158
                </p>
                <p>
                  You may always launch a complaint regarding our processing of your data with <a
                  href='http://ico.org.uk/'>ico.org.uk</a>
                </p>
              </Col>
            </Row>
          </StyledContainer>
        </Module>
      </section>
    );
  }
}

export default PrivacyPolicy;
