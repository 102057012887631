import PropTypes from 'prop-types';
import React from 'react';

const Logo = ({ className, height, width }) => (
  <div className={className}>
    <svg
      id="Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 148 48"
      fill="none"
      height={height}
      width={width}
    >
      <g id="home-instead-logo" clip-path="url(#LogoClipPath)">
        <g id="Vector">
          <path
            d="M78.5417 18.827C78.5417 16.6551 77.1212 15.1717 75.2398 15.1717C73.3583 15.1717 71.9379 16.6551 71.9379 18.827C71.9379 20.9988 73.3583 22.4822 75.2398 22.4822C77.1212 22.4822 78.5417 20.9988 78.5417 18.827ZM68.0067 18.827C68.0067 14.5333 71.0968 11.5603 75.2398 11.5603C79.3827 11.5603 82.4728 14.5145 82.4728 18.827C82.4728 23.1394 79.3827 26.0936 75.2398 26.0936C71.0968 26.0936 68.0067 23.1394 68.0067 18.827Z"
            fill="#2C4F26"></path>
          <path
            d="M90.7712 14.9151C89.3507 14.9151 88.5159 15.9165 88.5159 17.8693V25.6805C88.5159 25.7556 88.4536 25.812 88.3975 25.812H84.6159C84.5412 25.812 84.4976 25.7494 84.4976 25.6805V11.9734C84.4976 11.8983 84.5599 11.842 84.6159 11.842H88.3165C88.3913 11.842 88.4349 11.9045 88.4349 11.9734V13.7259C89.2386 12.3239 90.5967 11.5791 92.2414 11.5791C94.1665 11.5791 95.7116 12.4115 96.4965 14.1515C97.3002 12.5117 98.864 11.5791 100.77 11.5791C103.543 11.5791 105.611 13.3629 105.611 16.9993V25.6993C105.611 25.7744 105.549 25.8307 105.48 25.8307H101.699C101.624 25.8307 101.58 25.7682 101.58 25.6993V17.8881C101.58 15.9353 100.752 14.9339 99.325 14.9339C97.8983 14.9339 97.0448 15.9353 97.0448 17.8881V25.6993C97.0448 25.7744 96.9825 25.8307 96.9264 25.8307H93.1759C93.1012 25.8307 93.0451 25.7682 93.0451 25.6993V17.8881C93.0451 15.9353 92.2165 14.9339 90.7649 14.9339"
            fill="#2C4F26"></path>
          <path
            d="M111.399 17.5752H117.679C117.342 15.8101 116.239 14.8963 114.532 14.8963C112.825 14.8963 111.723 15.8164 111.399 17.5752ZM111.361 19.9473C111.673 21.7937 112.8 22.7951 114.638 22.7951C115.891 22.7951 116.707 22.3508 117.112 21.4682C117.137 21.4182 117.174 21.3931 117.23 21.3931H121.061C121.142 21.3931 121.205 21.4745 121.18 21.5496C120.276 24.5164 117.89 26.0748 114.526 26.0748C110.439 26.0748 107.499 23.0831 107.499 18.8082C107.499 14.5333 110.439 11.5415 114.526 11.5415C118.613 11.5415 121.566 14.5333 121.566 18.8082C121.566 19.1962 121.541 19.5593 121.504 19.9285H111.355V19.9411L111.361 19.9473Z"
            fill="#2C4F26"></path>
          <path
            d="M66.4118 36.809C64.8232 36.809 63.9136 37.898 63.9136 39.9948V47.5744C63.9136 47.6495 63.8513 47.7058 63.7952 47.7058H60.0136C59.9389 47.7058 59.8828 47.6432 59.8828 47.5744V33.8673C59.8828 33.7921 59.9451 33.7358 60.0136 33.7358H63.7018C63.7766 33.7358 63.8326 33.7984 63.8326 33.8673V35.7762C64.6612 34.2929 66.1377 33.4604 67.8946 33.4604C70.7915 33.4604 72.9534 35.3319 72.9534 39.0747V47.5806C72.9534 47.6558 72.8911 47.7121 72.8225 47.7121H69.0409C68.9662 47.7121 68.9225 47.6495 68.9225 47.5806V40.001C68.9225 37.898 68.0067 36.8152 66.4243 36.8152"
            fill="#2C4F26"></path>
          <path
            d="M81.3078 47.9875C77.7754 47.9875 75.0466 46.2475 74.9657 43.2307C74.9657 43.1556 75.028 43.0992 75.0965 43.0992H78.7348C78.8096 43.0992 78.8532 43.1493 78.8532 43.2181C78.9155 44.5075 79.9434 45.1397 81.2704 45.1397C82.4977 45.1397 83.2391 44.6953 83.2391 43.8002C83.2391 41.0588 75.2273 43.5248 75.2273 37.7666C75.2273 35.0878 77.6757 33.4729 80.8717 33.4729C84.2608 33.4729 86.8151 35.4758 86.9023 38.0608C86.9023 38.1359 86.8525 38.1922 86.784 38.1922H83.1457C83.0709 38.1922 83.0273 38.1421 83.0273 38.0733C82.965 37.0718 82.0928 36.3208 80.9153 36.3208C79.7378 36.3208 79.0775 36.7902 79.0775 37.6101C79.0775 40.0699 87.1952 37.8041 87.1952 43.5812C87.1952 46.3476 84.6595 48 81.2953 48"
            fill="#2C4F26"></path>
          <path
            d="M95.9982 44.5826C96.5028 44.5826 97.0573 44.4762 97.5619 44.3072V47.662C97.0074 47.831 96.0605 48 95.2319 48C92.2913 48 90.117 46.8546 90.117 42.9678V37.0406H88.111V33.7483H88.653C89.4566 33.7483 90.117 33.0974 90.117 32.2775V29.6112H94.1416V33.7483H97.5681V37.0406H94.1416V42.7362C94.1416 43.9943 94.7272 44.5826 96.0044 44.5826"
            fill="#2C4F26"></path>
          <path
            d="M102.558 39.4628H108.838C108.502 37.6977 107.399 36.7839 105.692 36.7839C103.985 36.7839 102.882 37.704 102.558 39.4628ZM102.533 41.8412C102.845 43.6876 103.973 44.689 105.81 44.689C107.063 44.689 107.879 44.2446 108.284 43.3621C108.309 43.312 108.346 43.287 108.402 43.287H112.234C112.315 43.287 112.377 43.3684 112.352 43.4435C111.449 46.4102 109.062 47.9687 105.698 47.9687C101.611 47.9687 98.6708 44.9769 98.6708 40.702C98.6708 36.4272 101.611 33.4354 105.698 33.4354C109.785 33.4354 112.738 36.4272 112.738 40.702C112.738 41.0901 112.713 41.4531 112.676 41.8224H102.527V41.8349L102.533 41.8412Z"
            fill="#2C4F26"></path>
          <path
            d="M119.865 45.002C121.535 45.002 122.974 44.0256 122.974 42.1541V41.4281C121.049 42.2981 118.108 42.0415 118.108 43.6625C118.108 44.5576 118.887 45.002 119.865 45.002ZM114.09 43.988C114.09 38.0983 122.93 40.7146 122.93 37.9794C122.93 37.003 121.983 36.4084 120.731 36.4084C119.348 36.4084 118.532 37.1219 118.482 38.4113C118.482 38.4864 118.42 38.5302 118.351 38.5302H114.626C114.551 38.5302 114.495 38.4676 114.495 38.3987C114.57 34.8374 117.641 33.4416 120.837 33.4416C124.033 33.4416 126.949 34.7185 126.949 38.0733V47.5619C126.949 47.637 126.887 47.6933 126.818 47.6933H123.18C123.105 47.6933 123.049 47.6307 123.049 47.5619V45.5715C122.158 47.274 120.551 47.975 118.626 47.975C116.32 47.975 114.059 46.7419 114.059 43.9817"
            fill="#2C4F26"></path>
          <path
            d="M132.68 40.7208C132.68 42.9302 134.101 44.3761 135.982 44.3761C137.864 44.3761 139.284 42.9302 139.284 40.7208C139.284 38.5114 137.889 37.0656 135.982 37.0656C134.076 37.0656 132.68 38.5114 132.68 40.7208ZM139.303 35.4195V28.0339C139.303 27.9588 139.365 27.9025 139.421 27.9025H143.203C143.278 27.9025 143.334 27.965 143.334 28.0339V47.5869C143.334 47.662 143.271 47.7183 143.203 47.7183H139.477C139.403 47.7183 139.347 47.6558 139.347 47.5869V45.9596C138.312 47.2489 136.873 48 135.179 48C131.428 48 128.731 45.0896 128.731 40.7333C128.731 36.3771 131.422 33.4667 135.179 33.4667C136.848 33.4667 138.269 34.1927 139.297 35.4195"
            fill="#2C4F26"></path>
          <path
            d="M54.986 32.0522C56.4065 32.0522 57.4033 31.0132 57.4033 29.7113C57.4033 28.4094 56.4065 27.3955 54.986 27.3955C53.5656 27.3955 52.5937 28.3969 52.5937 29.7113C52.5937 31.0257 53.6279 32.0522 54.986 32.0522Z"
            fill="#2C4F26"></path>
          <path
            d="M52.98 33.7421V47.5994C52.98 47.6745 53.0423 47.7309 53.0983 47.7309H56.8799C56.9547 47.7309 57.0108 47.6683 57.0108 47.5994V33.7421C57.0108 33.6482 56.9173 33.5856 56.8301 33.6232C56.2756 33.8735 55.6526 34.0112 54.9673 34.0112C54.282 34.0112 53.6964 33.8673 53.1544 33.6106C53.0734 33.5731 52.9737 33.6357 52.9737 33.7296"
            fill="#2C4F26"></path>
          <path
            d="M60.8734 11.5979C59.2162 11.5979 58.101 12.0673 56.9921 13.1063V3.84926C56.9921 3.84926 56.9298 3.7679 56.8986 3.78667L53.0921 6.22141C53.0921 6.22141 53.0547 6.25896 53.0547 6.284V25.7494C53.0547 25.7494 53.0672 25.7869 53.0921 25.7995C53.659 25.5491 54.2945 25.4114 54.9798 25.4114C55.7025 25.4114 56.3629 25.5554 56.936 25.812C56.9734 25.812 56.9983 25.7869 56.9983 25.7494V18.5453C56.9983 16.5049 57.8892 15.034 60.0261 15.178C61.5649 15.2844 62.406 16.1043 62.406 18.5453V25.7494C62.406 25.7494 62.4309 25.812 62.4683 25.812H66.2748C66.2748 25.812 66.3371 25.7869 66.3371 25.7494V17.0619C66.3371 13.1751 63.6956 11.5979 60.8734 11.5979Z"
            fill="#2C4F26"></path>
          <path
            d="M146.604 46.3414C146.71 46.3414 146.785 46.2913 146.785 46.1724C146.785 46.0535 146.71 46.0034 146.604 46.0034H146.343V46.3414H146.604ZM145.994 45.703H146.629C146.928 45.703 147.134 45.897 147.134 46.1599C147.134 46.3539 147.053 46.5104 146.885 46.5917L147.196 47.0486V47.0862H146.835L146.561 46.6418H146.343V47.0862H145.994V45.6842V45.703ZM147.576 46.3789C147.576 45.6904 147.165 45.3024 146.53 45.3024C145.894 45.3024 145.483 45.703 145.483 46.3789C145.483 47.0549 145.894 47.4555 146.53 47.4555C147.165 47.4555 147.576 47.0549 147.576 46.3789ZM145.091 46.3789C145.091 45.5465 145.676 44.9081 146.542 44.9081C147.408 44.9081 147.994 45.5465 147.994 46.3789C147.994 47.2114 147.396 47.8498 146.542 47.8498C145.689 47.8498 145.091 47.2114 145.091 46.3789Z"
            fill="#2C4F26"></path>
          <path
            d="M45.0803 14.5083C45.0679 14.4144 45.0242 14.333 44.9433 14.283L22.7146 0.0500703C22.6149 -0.0125194 22.4841 -0.0125194 22.3782 0.0500703L10.8215 7.44817C10.7343 7.5045 10.6782 7.60464 10.6782 7.71104V15.4659C10.6782 15.5911 10.8153 15.6662 10.915 15.5973L22.5464 8.15543L38.1962 18.176V40.7959H22.6398V32.8032C22.6398 27.408 19.6494 24.6165 15.9301 23.7715C15.0891 23.5775 14.2106 23.4836 13.326 23.4836C10.51 23.4836 8.59741 24.2785 6.71595 26.0498V10.3648C6.71595 10.2397 6.57889 10.1646 6.47921 10.2334L0.14329 14.2704C0.05607 14.3268 0 14.4269 0 14.5333V47.3991C0 47.5744 0.13706 47.7121 0.3115 47.7121H6.39822C6.56643 47.7121 6.70972 47.5744 6.70972 47.3991V35.1691C6.75956 31.7768 8.27968 29.3483 11.8744 29.5924C14.4972 29.7739 15.9301 31.1884 15.9301 35.3256V47.3991C15.9301 47.5744 16.0672 47.7121 16.2416 47.7121H44.775C44.9495 47.7121 45.0865 47.5744 45.0865 47.3991V14.5458C45.0865 14.5458 45.0803 14.5208 45.0741 14.5083H45.0803Z"
            fill="#2C4F26"></path>
        </g>
      </g>
      <defs>
        <clipPath id="LogoClipPath">
          <rect width="148" height="48" fill="white"></rect>
        </clipPath>
      </defs>
    </svg>
  </div>
);

Logo.propTypes = {
  className: PropTypes.string,
  height: PropTypes.string,
};

Logo.defaultProps = {
  className: 'logo',
  color: 'white',
  height: '100%',
  width: '100%',
};

export default Logo;
