let env;

if (window.global.DEVELOPMENT) {
  env = 'dev';
} else if (
  window.global.SUPERCARERS_API_URL.startsWith('https://api-staging')
) {
  env = 'staging';
} else {
  env = 'prod';
}

const theme = {
  env: env,
  colors: {
    anchor: '#2C4F26',
    beige: '#F8F6EF',
    backgroundGrey: '#F9F9F9',
    changeGreen: '#3EB38A',
    darkBlue: '#2E404F',
    darkBlueLow: '#24333F',
    darkerBeige: '#f9ca003d',
    uiGrey: '#D4E4E7',
    uiDarkGrey: '#4C7E88',
    formGrey: '#F1F1F1',
    formText: '#4A4A4A',
    hiAccentBlue: '#2C4F26',
    hiAccentBlueHover: '#2C4F26',
    hiAccentPink: '#ba135f',
    hiAccentGold: '#998b39',
    hiCream: '#e8e1d6',
    hiLightBlue: '#b5b6c6',
    hiPurple: '#2C4F26',
    hiPurpleHover: '#2C4F26eb',
    hiCta: '#DEED6A',
    hiCtaHover: '#DEED6Aeb',
    hiTan: '#d6cbc4',
    lightBlue: '#56A6D1',
    lightBlueHover: '#5EB8E8',
    lightGreenHover: '#49C399',
    lightestBlue: '#EEF6FA',
    lightestGrey: '#FCFCFC',
    lightestRed: '#FBEFEF',
    lightGrey: '#F7F7F7',
    buttonBlue: '#2C4F26',
    midLightGrey: '#E6E6E6',
    midGrey: '#979797',
    orange: '#DF6150',
    red: '#D35663',
    shadowBlue: 'rgba(86, 166, 209, 0.6)',
    shadowGrey: 'rgba(0, 0, 0, 0.15)',
    shadowRed: 'rgba(216, 102, 99, 0.60)',
    shadowOrange: '#F08218',
    statusBlue: '#4187D9',
    statusGreen: '#44B28B',
    statusGrey: '#4A4A4A',
    statusOrange: '#F7892E',
    statusPurple: '#551a8b',
    statusRed: '#ED205E',
    warningRed: '#D86663',
    white: '#FFFFFF',
    tabs: {
      purple: '#2C4F26',
      yellow: '#998b39',
      blue: '#DEED6A',
      green: '#39b951',
    },
    calendar: ['#DEED6A', '#618da5', '#36647c', '#244252'],
    calendarAmend: '#F5b753',
    calendarAdhoc: '#B662a6',
  },
  screen: {
    xSmall: 576,
    small: 768,
    medium: 992,
    large: 1200,
  },
  typography: {
    fontStack: "'Open Sans', sans-serif",
    defaultFontSize: '16px',
    defaultLineHeight: '26px',
  },
  zIndexes: {
    menu: '50',
    modal: '999',
    nav: '70',
    title: '60',
  },
};

export default theme;
